@use "../global-variables" as *;

.user-bubble {
  border-radius: 20px 20px 2px 20px;
  padding: 20px;
  margin: 5px;
  background: #e3e9ff;
  max-width: 100%;
  width: fit-content;
  align-self: flex-end;
}

.assistant-avatar {
  height: 40px;
  margin-right: 10px;
}
.assistant-bubble-row {
  display: flex;
  align-items: flex-end;
}

.assistant-bubble {
  border-radius: 20px 20px 20px 2px;
  padding: 20px;
  margin: 5px;
  background: #f7f7f7;
  width: 100%;
  &.floating {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background-color: $sapphire-05;
    color: $sapphire-150;
    margin: 0;
    padding: 8px 20px;
    border: 0;
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.chatbot-prompt {
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: #d9def4 1px solid;
  padding: 12px;
  background-color: white;
  width: 100%;
  color: #202d63;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.chatbot-prompt-text {
  width: 80%;
  text-align: left;
}

.chatbot-prompt:hover {
  background: #f7f7f7;
}

.chatbot-prompt-container {
  border-top: #d9def4 1px solid;
  border-right: #d9def4 1px solid;
  border-left: #d9def4 1px solid;
  border-radius: 4px;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.chatbot-input {
  border-radius: 4px;
  padding: 8px 8px 8px 12px;
  height: 48px;
  border: solid 1px #a9aaac;
  background-color: #fff;
  margin: 10px;
}

.full-screen-wrapper > .chatbot-card.card {
  top: 20px;
  right: 5%;
  left: 5%;
  width: 90%;
  height: 94%;
  align-items: center;
  > div {
    max-width: 750px;
    width: 100%;
  }
  .chatbot-header {
    max-width: 100%;
  }
  .conversation-focus-dropdown {
    width: 100%;
  }
}

.chatbot-card.card {
  bottom: 20px;
  right: 20px;
  width: 400px;
  z-index: 1001;
  overflow: auto;
  height: 90vh;
  position: fixed;
}

.chatbot-fullscreen-toggle {
  margin-left: 10px;
  margin-bottom: 3px;
  font-size: 14px;
  color: #425cc7;
  cursor: pointer;
}

.chatbot-fullscreen-toggle:hover {
  color: #202d63;
}

.chatbot-header {
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

.chatbot-message-container {
  margin-top: auto;
  overflow-y: scroll;
  display: flex;
  padding: 10px;
}

.inner-chatbot-message-container {
  display: flex;
  flex-direction: column-reverse;
}

.chatbot-links {
  margin-left: 10px;
  margin-bottom: 20px;
  color: #53565a;
  a {
    color: #425cc7;
  }
}

.chatbot-x-mark {
  color: #425cc7;
  cursor: pointer;
}

.chatbot-x-mark:hover {
  color: #202d63;
}

.floating-chatbot-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
  border-radius: 40px;
  background: linear-gradient(223deg, #e3e9ff -24.96%, #fff 123.08%);
  padding: 8px 16px;
  border: none;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #202d63;
  font-weight: 500;
  cursor: pointer;
}

.typing-dots .dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 5px;
  background: gray;
  border-radius: 50%;
  animation: dot 1.2s infinite;
}

.disclaimer-box {
  padding: 10px;
  color: #75787b;
}
.disclaimer-button {
  border: none;
  background: none;
  text-decoration: underline;
  color: #75787b;
}

.disclaimer-text {
  padding-left: 10px;
}

.typing-dots .dot:nth-child(2) {
  animation-delay: 0.2s;
}

.typing-dots .dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes dot {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

.conversation-focus-title {
  padding-left: 20px;
  font-weight: bold;
}

.conversation-focus-dropdown {
  display: flex;
  width: 360px;
  padding: 8px 8px 8px 12px;
  margin-left: 20px;
  align-items: center;
  gap: 8px;
  border-radius: var(--spacing-0px, 4px);
  border: 1px solid #86888b;
  background: var(--Neutral-White-100, #fff);
  font-size: 12px;
}

.conversation-focus-dropdown .dropdown-menu {
  transform: translate(-2px, 38px) !important;
  width: 360px !important;
}

.conversation-focus-dropdown-item {
  padding: 8px 8px 8px 12px;
  color: #001f4e !important;
}

.conversation-focus-dropdown .dropdown-toggle {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.conversation-focus-category-header {
  margin-top: 10px;
  > span {
    font-size: 12px;
    padding-left: 16px;
    color: var(--Charcoal-Charcoal-100, var(--Charcoal-Charcoal-100, #53565a));
  }
}

.floating-chatbot-feedback-card {
  position: fixed;
  background: red;
  height: fit-content;
  width: 420px;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
  padding: 16px;
  gap: 12px;
  background-color: $sapphire-05;
  border: 1px solid $sapphire-100;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
}

.coach-bo-greeter-card-container {
  > .coach-bo-greeter-cards {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 24px;
  }
  .coach-bo-greeter-card {
    padding: 20px;
    padding-top: 75px;
    &.main-card {
      flex-direction: row;
      gap: 15px;
    }
  }

  .coach-bo-greetings {
    display: flex;
    gap: 40px;
    align-items: center;
    position: relative;
    margin-bottom: -60px;
    padding-left: 24px;
    z-index: 1;
    .assistant-avatar {
      width: 80px;
      height: 80px;
    }
    .assistant-avatar-container {
      padding: 20px;
      background-color: $sapphire-10;
      border-radius: 50%;
      border-color: $sapphire-20;
    }
  }
  .coach-bo-greetings-message {
    border-radius: 24px 24px 24px 2px;
    background-color: $sapphire-10;
    border: none;
    color: #202d63;
    padding: 22px 24px;
  }
  .coach-bo-greetings-message-text {
    color: $sapphire-150;
    font-size: 20px;
    font-weight: 600;
    font-family: "Montserrat";
  }
  .vertical-line {
    min-width: 8px;
    width: 8px;
    height: 100%;
    border-radius: 4px;
    &.green {
      background-color: $indicator-success-green-100;
    }
    &.blue {
      background-color: $sapphire-100;
    }
    &.yellow {
      background-color: $gold-100;
    }
  }

  // when screen size is less than 991px > .coach-bo-greeter-cards grid will be 1fr
  // .days-remaining-card will have padding-top of 20px
  @media (max-width: 991px) {
    > .coach-bo-greeter-cards {
      grid-template-columns: 1fr;
    }
    .days-remaining-card {
      padding-top: 20px;
    }
  }
}

.time-picker-dropdown {
  padding: 4px 16px 0px 16px;
}
