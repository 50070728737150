@use "../global-variables" as *;

.filters {
  display: flex;
  gap: 12px;

  > .filter {
    position: relative;
    > .filter-button {
      @include list-span;
      display: flex;
      gap: 8px;
      align-items: center;
      width: fit-content;
      font-size: 14px;
      &.active,
      &:hover {
        color: white;
        background-color: $sapphire-100;
      }
    }
    > .search-filter-dropdown-menu {
      position: absolute;
      z-index: 99;
      min-width: 182px;
      background-color: white;
      border: solid 1px $sapphire-20;
      > .filter-option {
        display: flex;
        gap: 8px;
        padding: 16px;
        border-bottom: solid 1px $sapphire-20;
        &:last-child {
          border-bottom: none;
        }
        > label {
          width: 100%;
          cursor: pointer;
        }
        > input[type="checkbox"] {
          cursor: pointer;
        }
      }
    }
  }
}

.circular-tabs {
  display: flex;
  gap: 12px;
  > .tab {
    padding: 4px 16px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    gap: 8px;
    border: solid 1px $charcoal-50;
    transition: background-color 0.2s ease-in-out;
    &:hover {
      background-color: $sapphire-10;
    }
    &.active {
      border-color: $sapphire-100;
    }
    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
      &:hover {
        background-color: white;
      }
    }
    > .tab-count {
      color: white;
      background-color: $sapphire-150;
      border-radius: 50%;
      height: 16px;
      min-width: 16px;
      padding: 2px;
      font-size: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
