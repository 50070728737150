@use "../global-variables" as *;

.sortable-table {
  width: 100%;
  > thead > tr {
    border-bottom: 2px #d8d9d9 solid;
    > th {
      padding-top: 18px;
      padding-bottom: 18px;
      white-space: nowrap;
    }
  }
  > tbody > tr {
    border-bottom: 1px #d8d9d9 solid;
  }
  td,
  th {
    padding: 18px 0 18px 12px;
    max-width: 280px;
    font-size: 14px;
  }
  .clickable {
    cursor: pointer;
    transition: background-color 0.3s ease; /* Adjust the duration and timing function as needed */
    &:hover {
      background-color: $charcoal-10;
    }
  }
  .label-tag {
    font-size: 14px;
  }
}

.admin-console-table {
  th {
    width: 160px;
  }
  td,
  th {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    max-width: 280px;
  }
  tr > td:last-child {
    overflow-x: visible;
    .dropdown {
      position: unset;
    }
  }
  tr.deactivated {
    color: $charcoal-80;
    .user-name-cell > .user-name-cell__job-title {
      color: $charcoal-50;
    }
  }
}

.user-name-cell {
  > .user-name-cell__job-title {
    font-size: 12px;
    color: $charcoal-70;
  }
}

.user-name-cell__name,
.user-name-cell__job-title {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  max-width: 246px;
}

.invitee-cell {
  display: flex;
  align-items: center;
  gap: 10px;
  > p,
  > a {
    max-width: 160px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
  }
}

.simple-pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 8px;
  .page-item {
    &.selected {
      button {
        background-color: $sapphire-100 !important;
        color: #fff !important;
      }
    }
  }
}

th.table-dropdown-header {
  width: 30px;
}

.editable-table {
  width: calc(100% + 20px);
  td {
    padding: 20px;
    padding-left: 0px;
    padding-bottom: 0px;
  }
  tr:first-child td {
    padding-top: 2px;
  }
  // hiding lastpass icon in editable table
  [data-lastpass-icon-root] {
    display: none !important;
  }
}

.copy-email-cell {
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  &.hovered {
    margin-right: 10px;
  }
  .copy-icon {
    position: absolute;
    right: 0px;
  }
}

.sortable-table.email-manager-table {
  td {
    font-size: 14px;
  }
  td,
  th {
    max-width: none;
  }
  .email-name-column-header {
    width: 220px;
  }
  .toggle-column-header {
    width: 260px;
  }
  .toggle-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 60px;

    button {
      white-space: nowrap;
    }
  }
  tr {
    th:first-child,
    td:first-child {
      padding: 0;
    }
  }
}
