@use "global-variables" as *;
@use "./imports";

/* 
  ⚠️ Heads up ! Reminder!
  Nested rules are super helpful,
  but they can also make it hard to visualize how much CSS you’re actually generating. The deeper you nest,
  the more bandwidth it takes to serve your CSS and the more work it takes the browser to render it. Keep those selectors shallow !
*/
* {
  font-family: "Lato";
}

h1,
h2,
h3,
h4,
h5 {
  color: $sapphire-150;
  font-weight: 700;
  margin-bottom: 0px;
}

h1 {
  font-size: 24px;
  line-height: 32px;
}

h2 {
  font-size: 20px;
  line-height: 30px;
}

h3 {
  font-size: 16px;
  line-height: 24px;
}

h4 {
  font-size: 14px;
  line-height: 20px;
}

p {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0;
  font-feature-settings: "liga" off, "clig" off;
}

#root {
  margin-bottom: 100px;
}

a {
  color: $sapphire-100;
}

.small-body-text {
  font-size: 12px;
  line-height: 14.4px;
}

body {
  background-color: #f7f7f7;
}

.text-grey-1 {
  color: $grey-1;
}
.form-label {
  white-space: nowrap;
  font-size: 14px !important;
}
button.no-border,
button.no-border:hover {
  border: none;
}

.multi-card-container-same-width {
  display: flex;
  flex-wrap: wrap;
  & > * {
    flex: 1 1 0px; /* Grow and shrink equally, basis of 0px */
  }
}

.contact-button {
  background-color: $sapphire-100;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  a {
    color: white;
  }
}

button.button-link,
.button-link {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  text-align: inherit;
  font: inherit;
  border-radius: 0;
  appearance: none;
  height: fit-content;
  cursor: pointer;
  color: $sapphire-100;
  text-decoration: underline;
  &:hover,
  &:focus {
    text-decoration: underline;
    color: $sapphire-130;
  }
}

.padding-16px-12px {
  padding: 16px 12px;
}

.simple-nav.nav {
  gap: 24px;

  .nav-item:has(> .active),
  .nav-item:has(> .active):hover {
    border-bottom: 4px solid $interactive-color;
  }

  .nav-item:hover {
    border-bottom: 4px solid $charcoal-20;
  }
  .nav-item {
    padding-bottom: 18px;
    font-size: 16px;
    transition: border-bottom 0.3s ease;
    border-bottom: 4px solid transparent;
  }
  .nav-link {
    padding: 0px;
    font-weight: bold;
    color: $charcoal-100;
    &.active {
      color: $interactive-color;
    }
    &.disabled {
      color: $charcoal-50;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
  &.disabled {
    .nav-item {
      cursor: not-allowed;
      border-bottom: none !important;
      > .nav-link {
        color: $charcoal-50;
      }
    }
  }
}
// ------------------  Utility Styles ------------------ //
.single-line-clamp {
  @include line-clamp(1);
}

.dynamic-line-clamp {
  @include line-clamp(var(--line-clamp, 1));
}

.column-gap-36px,
.column-gap-32px,
.column-gap-24px,
.column-gap-20px,
.column-gap-18px,
.column-gap-16px,
.column-gap-12px,
.column-gap-10px,
.column-gap-8px,
.column-gap-6px,
.column-gap-4px,
.column-gap {
  display: flex;
  flex-direction: column;
}

.row-gap-36px,
.row-gap-32px,
.row-gap-24px,
.row-gap-20px,
.row-gap-18px,
.row-gap-16px,
.row-gap-12px,
.row-gap-10px,
.row-gap-8px,
.row-gap-6px,
.row-gap-4px,
.row-gap {
  display: flex;
  flex-direction: row;
}

.column-gap-4px,
.row-gap-4px {
  gap: 4px;
}

.column-gap-6px,
.row-gap-6px {
  gap: 6px;
}

.column-gap-8px,
.row-gap-8px {
  gap: 8px;
}

.column-gap-10px,
.row-gap-10px {
  gap: 10px;
}

.column-gap-12px,
.row-gap-12px {
  gap: 12px;
}

.column-gap-16px,
.row-gap-16px {
  gap: 16px;
}

.row-gap-18px,
.column-gap-18px {
  gap: 18px;
}

.row-gap-36px,
.column-gap-36px {
  gap: 36px;
}

.row-gap-32px,
.column-gap-32px {
  gap: 32px;
}

.row-gap-24px,
.column-gap-24px {
  gap: 24px;
}

.row-gap-20px,
.column-gap-20px {
  gap: 20px;
}

.padding-20px {
  padding: 20px;
}

.no-underline {
  text-decoration: none;
}

.text-underline {
  text-decoration: underline;
}

.btn-as-link {
  background: none;
  border: none;
  padding: 0;
  color: $sapphire-100;
  cursor: pointer;
  text-decoration: underline;
  font-size: 14px;
}

// ------------------  END Utility Styles ------------------ //
hr {
  color: $sapphire-20;
  opacity: 1;
}

.card,
.full-border {
  border: solid 1px #d9def4;
}

.border-bottom,
.border-y {
  border-bottom: solid 1px #d9def4;
}

.border-top,
.border-y {
  border-top: solid 1px #d9def4;
}

.border-right,
.border-x {
  border-right: solid 1px #d9def4;
}

.border-left,
.border-x {
  border-left: solid 1px #d9def4;
}

.ti-badge {
  border-radius: 4px;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.page {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  margin-top: 20px;
  padding-bottom: 120px;
}

.list-span {
  @include list-span;
  &.lg {
    font-size: 14px;
  }
}

.circular-list-span {
  white-space: nowrap;
  display: flex;
  background-color: $sapphire-10;
  border-radius: 20px;
  font-size: 12px;
  padding: 8px 16px;
  align-items: center;
  gap: 4px;
  color: black;
}

.nowrap {
  white-space: nowrap;
}

textarea {
  resize: none;
}
textarea.resizable {
  resize: vertical;
}

.no-caret {
  border: none !important;
  &.dropdown-toggle::after {
    display: none;
  }
}
.dropdown-toggle,
.dropdown-item {
  font-size: 14px;
}

.css-13cymwt-control {
  font-size: 14px;
  border-color: $sapphire-20 !important;
}

.setting-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  > .setting-row-description {
    > h3 {
      color: black;
    }
    > p {
      color: $charcoal-100;
    }
  }
}

.setting-row-disclaimer-button {
  border: none;
  background: none;
  color: $sapphire-100;
  text-decoration: underline;
  padding: 0px;
}

@media (min-width: 576px) {
  .modal-dialog.modal-lg {
    max-width: 720px;
    margin-right: auto;
    margin-left: auto;
  }
}

.small-grey-text {
  color: $charcoal-100;
  font-size: 12px;
}

.grey-text {
  color: $charcoal-100;
}
.navy-text {
  color: $navy-100;
}
.sapphire-150-text {
  color: $sapphire-150;
}

.dropdown {
  > button {
    > [data-icon="ellipsis"] {
      color: $charcoal-100;
      font-size: 24px;
    }
  }
}

.flip.dropdown-menu {
  right: 0 !important;
  left: auto !important;
}

.full-width-dropdown {
  > .dropdown-toggle {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  > .dropdown-menu {
    width: 100%;
  }
}

.center-of-page-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.date-and-time {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.light-tooltip {
  &.show {
    opacity: 1;
  }
  > .tooltip-arrow::before {
    border-bottom-color: $charcoal-05;
  }
  > .tooltip-inner {
    background-color: $charcoal-05;
    color: $charcoal-100;
  }
}

.no-max-width-tooltip > .tooltip-inner {
  max-width: none;
}

#notification-dropdown {
  padding: 0 10px;
  display: flex;
  align-items: center;
}

.warning-banner {
  background-color: $gold-10;
  color: $gold-150;
  border: 1px solid $gold-20;
  border-radius: 4px;
  padding: 16px;
  &.light-blue {
    background-color: $sapphire-05;
    color: $sapphire-150;
    border-color: $sapphire-100;
  }
  &.blue,
  &.lighter-blue {
    background-color: $sapphire-10;
    color: $sapphire-150;
    border-color: $sapphire-100;
  }
  &.blue {
    background-color: $sapphire-10;
    border-color: $sapphire-20;
  }
  &.lighter-blue {
    background-color: $sapphire-05;
  }
  &.green {
    background-color: $indicator-success-green-20;
    color: $indicator-success-green-120;
    border-color: $indicator-success-green-100;
  }
  &.red {
    background-color: $ruby-10;
    color: $ruby-130;
    border-color: $ruby-100;
  }
  &.light-red {
    background-color: $indicator-error-red-10;
    border-color: $indicator-error-red-100;
    color: $ruby-100;
  }
  &.gold {
    background-color: $gold-10;
    color: $gold-150;
    border-color: $gold-20;
  }
  &.grey {
    background-color: $charcoal-05;
    color: $charcoal-100;
    border-color: $charcoal-20;
  }
}

ul.bulletless-list {
  padding-left: 0;
  > li,
  ol {
    list-style: none;
  }
}

.font-family-lato {
  font-family: "Lato";
}

.font-family-montserrat {
  font-family: "Montserrat";
}

.OR-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  > hr {
    width: 100%;
  }
}
